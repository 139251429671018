import React from "react";
import classnames from "classnames";

const Templates = ({ className }) => (
  <svg
    className={classnames(className)}
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 3a3 3 0 00-3-3H3a3 3 0 00-3 3v18a3 3 0 003 3h18a3 3 0 003-3V3zM9.654 2a.966.966 0 011.692 0c.096.15.15.322.154.5a.969.969 0 01-.154.5.966.966 0 01-1.692 0 .969.969 0 01-.154-.5.969.969 0 01.154-.5zm-3.5 0a.966.966 0 011.692 0c.096.15.15.322.154.5a.969.969 0 01-.154.5.966.966 0 01-1.692 0A.969.969 0 016 2.5a.969.969 0 01.154-.5zm-3.562.092A1 1 0 013.5 1.5a.985.985 0 01.846.5c.096.15.15.322.154.5a.969.969 0 01-.154.5.966.966 0 01-1.692 0 .969.969 0 01-.154-.5c0-.141.032-.28.092-.408zM3 22a1 1 0 01-1-1V5.25A.25.25 0 012.25 5h6a.25.25 0 01.25.25v16.5a.25.25 0 01-.25.25H3zm19-1a1 1 0 01-1 1H10.25a.25.25 0 01-.25-.25V5.25a.25.25 0 01.25-.25h11.5a.25.25 0 01.25.25V21zM18.5 7.25h-5a1 1 0 00-1 1v3.5a1 1 0 001 1h5a1 1 0 001-1v-3.5a1 1 0 00-1-1zm-5.25 8.25h5.5a.75.75 0 110 1.5h-5.5a.75.75 0 110-1.5zM3.5 7.25a.75.75 0 00.75.75h2a.75.75 0 000-1.5h-2a.75.75 0 00-.75.75zM6.25 12h-2a.75.75 0 110-1.5h2a.75.75 0 110 1.5zm-2 4h2a.75.75 0 100-1.5h-2a.75.75 0 100 1.5zm2 4h-2a.75.75 0 110-1.5h2a.75.75 0 110 1.5z"
    />
  </svg>
);

export default Templates;
